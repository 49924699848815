import { useState, useMemo, useEffect, createContext, useContext } from "react";
import axios from "axios";

import useAuth from "./useAuth";
import useConfig from "./useConfig";

import { API_BASE_URL } from "constants/urls";

const PortalContext = createContext();

export const PortalProvider = ({ children }) => {
  const { authToken, logout } = useAuth();
  const { channelId } = useConfig();

  const [destinations, setDestinations] = useState([]);
  const [loadingDestinations, setLoadingDestinations] = useState(true);

  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  const [filters, setFilters] = useState({});
  const [loadingFilters, setLoadingFilters] = useState(true);

  const [portalLoaded, setPortalLoaded] = useState(false);

  const handleApiError = (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      logout();
    }
  };

  const fetchPortalData = async () => {
    try {
      setLoadingCategories(true);
      setLoadingDestinations(true);
      setLoadingFilters(true);

      const responseCategories = await axios({
        url: `${API_BASE_URL}/api/v/categories`,
        method: "get",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
        params: { embed: ["totalProducts"], channelId },
      });

      const responseDestinations = await axios({
        url: `${API_BASE_URL}/api/v/destinations`,
        method: "get",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
        params: { embed: ["regions", "totalProducts"], channelId },
      });

      const responseFilters = await axios({
        url: `${API_BASE_URL}/api/v2/products/filters`,
        method: "get",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
        params: { channelId },
      });

      setCategories(responseCategories.data.categories);
      setDestinations(responseDestinations.data.destinations);
      setFilters(responseFilters.data.filters);

      setLoadingCategories(false);
      setLoadingDestinations(false);
      setLoadingFilters(false);
    } catch (error) {
      handleApiError(error);
    }
  };

  const getProducts = async (params = {}) => {
    try {
      const response = await axios({
        url: `${API_BASE_URL}/api/v2/products`,
        method: "get",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
        params: { ...params, channelId },
      });

      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const getProduct = async (id, params = {}) => {
    try {
      const response = await axios({
        url: `${API_BASE_URL}/api/v2/products/${id}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
        params: { ...params, channelId },
      });

      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    if (authToken && channelId) {
      fetchPortalData();
    }
  }, [authToken, channelId]);

  useEffect(() => {
    if (!loadingCategories && !loadingDestinations && !loadingFilters) {
      setPortalLoaded(true);
    }
  }, [loadingCategories, loadingDestinations, loadingFilters]);

  const value = useMemo(
    () => ({
      destinations,
      loadingDestinations,
      categories,
      loadingCategories,
      filters,
      loadingFilters,
      portalLoaded,
      getProducts,
      getProduct,
    }),
    [
      destinations,
      categories,
      filters,
      loadingFilters,
      portalLoaded,
      getProducts,
      getProduct,
    ]
  );

  return (
    <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
  );
};

export default function usePortal() {
  return useContext(PortalContext);
}
