import React, { Fragment } from "react";
import { Tab } from "@headlessui/react";

function Timeline({ timeline, timelinePlaces }) {
  const getPlace = (id) => {
    if (id) {
      const place = timelinePlaces.find((place) => place._id === id);
      return place.name.EN;
    }
  };

  const getDayTimeline = (day, dayIndex) => (
    <div>
      <div className="flex my-5 md:my-0">
        <div className="flex-shrink-0 flex flex-col items-center py-2">
          <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
          {day.slice(0, -1).map(() => showStage())}
        </div>
        <div className="ml-4 space-y-10">
          {getDayTimelineSteps(day, dayIndex)}
        </div>
      </div>
    </div>
  );

  const getDayTimelineSteps = (day, dayIndex) => {
    return day.map((stage, index) => {
      const place = getPlace(stage.place[0]);
      return (
        <div key={`${dayIndex}-${index}`} className="flex flex-col space-y-1">
          <span className="text-neutral-500 dark:text-neutral-400">
            {stage.time && stage.time} {stage.time && place && <span>·</span>}{" "}
            {place}
          </span>
          <span className="text-neutral-700 dark:text-neutral-300">
            {stage.phase}
          </span>
        </div>
      );
    });
  };

  const showStage = () => {
    return (
      <>
        <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
        <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
      </>
    );
  };

  return (
    <div>
      {timeline.length === 1 ? (
        getDayTimeline(timeline[0], 0)
      ) : (
        <Tab.Group>
          {timeline.length > 1 && (
            <Tab.List className="mb-8 flex space-x-1 overflow-x-auto">
              {timeline.map((item, index) => (
                <Tab key={index} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-100 text-neutral-500 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      Day {index + 1}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
          )}

          <Tab.Panels>
            {timeline.map((day, dayIndex) => (
              <Tab.Panel key={dayIndex}>
                {getDayTimeline(day, dayIndex)}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      )}
    </div>
  );
}

export default Timeline;
