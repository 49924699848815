import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "hooks/useAuth";
import { PortalProvider } from "hooks/usePortal";

import useConfig from "hooks/useConfig";

import B2BPortalRoutes from "routers/B2BPortalRoutes";

import ScrollToTop from "./routers/ScrollToTop";

function App() {
  const { appLoaded, appId, appBasePath, company } = useConfig();

  // Workaround: Redirect existings urls following path (cellarhopping) -> /b2bportal/:id
  // TODO: Remove redirects after a period of time
  useEffect(() => {
    const windowUrl = window.location.href;
    const channelIdsToRedirect: string[] = [
      "62a36c3c3bcff1567c1c7171",
      "62cd4638a04c01fa608b3f7b",
      "62e5670d9812f0522cbcb5ff",
      "63329d79c3a1c5294260c912",
      "634bedf4468b63e88a3ffae5",
      "636426a5b438aca67746acf3",
      "63650689b438aca67746ae98",
      "6367977db438aca67746b3c8",
      "63679c06b438aca67746b3f1",
      "6367a104b438aca67746b400",
      "6367a778b438aca67746b423",
      "6367cc66b438aca67746b451",
      "636a26d3b438aca67746b8ab",
      "636a29e4b438aca67746b8b9",
      "636fca137169535f635d3501",
      "6377a45d825dba15ff288557",
      "6378cb54825dba15ff288749",
      "6378f221825dba15ff2887a7",
      "637b4178825dba15ff288b6f",
      "637b4429825dba15ff288b7b",
      "637b45ed825dba15ff288b82",
      "637f3c2cb8885b52cecb4833",
      "6387430710a0be0ee16d529a",
      "639a11fbcef7dca63878e372",
      "639a12a6cef7dca63878e379",
      "63ce607953a02fd09692acb2",
      "63ce60e953a02fd09692acb4",
      "63d0e7810dbbc022ff0f11e0",
      "63ea20560b47e75318de064b",
      "63ef4868354bc83804b2301a",
      "63f387544e780ca2821bdf26",
      "63f65585f61504b97969ed6d",
      "63f7a1b4261c71f8f0b2a08d",
      "64107b8a67d4ba2d92c72503",
      "6418cc5e37e4c8cd53d701e1",
      "641c6b6737e4c8cd53d70abc",
      "6422d30c60ebe7ad99da0210",
    ];

    const channelIdInUrl = channelIdsToRedirect.find((channelId) =>
      windowUrl.includes(`/b2bportal/${channelId}`)
    );

    if (channelIdInUrl) {
      window.location.href = window.location.href.replace(
        `/b2bportal/${channelIdInUrl}`,
        "/b2bportal"
      );
    }
  }, []);

  if (!appLoaded) {
    return <></>;
  }

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <Helmet>
        <title>Welcome - {company.name}</title>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={company.logo[0].url}
        />
      </Helmet>

      {appId === "b2bportal" && (
        <BrowserRouter basename={appBasePath}>
          <ScrollToTop />
          <AuthProvider>
            <PortalProvider>
              <B2BPortalRoutes />
            </PortalProvider>
          </AuthProvider>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
