import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import Loading from "components/Loading/Loading";

import ClearDataButton from "./ClearDataButton";

import usePortal from "hooks/usePortal";

const CategoryPickerInput = ({
  autoFocus = false,
  onChange,
  placeHolder = "Category",
  desc = "Select Category",
  className = "nc-flex-1.5",
}) => {
  const { categories, loadingCategories } = usePortal();

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);

  const [searchParams] = useSearchParams();
  const queryCategoryId = searchParams.get("categoryId");

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }

    showPopover && document.addEventListener("click", eventClickOutsideDiv);

    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event) => {
    if (!containerRef.current) return;

    if (!showPopover || containerRef.current.contains(event.target)) {
      return;
    }

    setShowPopover(false);
  };

  const handleSelectCategory = (categoryId) => {
    const category = categories.find((cat) => cat._id === categoryId);

    onChange(category._id);

    setValue(category.name.EN);
    setShowPopover(false);
  };

  useEffect(() => {
    if (queryCategoryId) {
      const defaultCategory = categories.find(
        (category) => category._id === queryCategoryId
      );

      if (defaultCategory) {
        handleSelectCategory(defaultCategory._id);
      }
    }
  }, [categories]);

  const clearValue = () => {
    setValue("");
    onChange(undefined);
  };

  const renderCategories = () => {
    return (
      <>
        {categories.map((category) => (
          <div key={category._id}>
            <h3
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
              style={{ cursor: "pointer" }}
              onClick={() => handleSelectCategory(category._id)}
            >
              {category.name.EN}
            </h3>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <i className="las la-filter text-3xl"></i>
        </div>
        <div className="flex-grow">
          {loadingCategories ? (
            <Loading align="left" />
          ) : (
            <>
              <input
                className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                placeholder={placeHolder}
                value={value}
                autoFocus={showPopover}
                onChange={(e) => setValue(e.currentTarget.value)}
                ref={inputRef}
                readOnly
              />
              <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                <span className="line-clamp-1">
                  {!!value ? placeHolder : desc}
                </span>
              </span>
              {value && showPopover && <ClearDataButton onClick={clearValue} />}
            </>
          )}
        </div>
      </div>
      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderCategories()}
        </div>
      )}
    </div>
  );
};

export default CategoryPickerInput;
