import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";

export const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  const { loggedIn } = useAuth();

  if (!loggedIn) {
    // User is not authenticated
    return <Navigate to="/login" replace state={{ path: location.pathname }} />;
  }

  return children;
};
