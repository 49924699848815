import React, { FC } from "react";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

import ModalPhotos from "containers/ProductPage/ModalPhotos";
import { PhotoType } from "data/types";

export interface SectionHeroProps {
  title: string;
  subtitle?: string;
  richTextSubtitle?: boolean;
  description?: string;
  richTextDescription?: boolean;
  className?: string;
  photoUrl?: string;
  photos?: PhotoType[];
  centerTitle?: boolean;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  title,
  subtitle,
  richTextSubtitle = false,
  description,
  richTextDescription = false,
  photos = [],
  centerTitle = false,
}) => {
  const renderSliderGallery = () => {
    return (
      <ModalPhotos
        photos={photos}
        uniqueClassName="nc-ListingExperiencesDetailPage__modalPhotos"
      />
    );
  };

  return (
    <div
      className={`nc-SectionHero lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className={centerTitle ? "text-center" : "text-left"}>
        <h2 className="font-medium text-4xl md:text-5xl xl:text-6xl ">
          {title}
        </h2>

        {subtitle && (
          <p className="mt-5 text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            {richTextSubtitle ? (
              <div dangerouslySetInnerHTML={{ __html: subtitle }} />
            ) : (
              subtitle
            )}
          </p>
        )}

        {description && (
          <p className="mt-5 text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            {richTextDescription ? (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
              description
            )}
          </p>
        )}
      </div>

      <div className="z-10 mt-20 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
