import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import { API_BASE_URL } from "constants/urls";

import useConfig from "hooks/useConfig";

import axios from "axios";
import Button from "shared/Button/Button";

const ForgotPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { company } = useConfig();

  const [email, setEmail] = useState(undefined);
  const [showSuccess, setShowSuccess] = useState(false);

  const sendResetPasswordLink = (e) => {
    e.preventDefault();
    axios({
      url: `${API_BASE_URL}/api/public/users/reset`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        emailTo: email,
        redirectUrl: location.state.loginUrl,
      },
    }).then(({ data }) => {
      if (data.success) {
        setShowSuccess(true);
      }
    });
  };

  return (
    <div>
      <Helmet>
        <title>Reset Password - {company.name}</title>
      </Helmet>

      {showSuccess ? (
        <div className="container mb-24 lg:mb-32 text-center">
          <h2 className="mt-20 mb-10 flex items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            We received your request!
            <i
              class="las la-check-circle items-center justify-center text-primary-800 ml-1"
              style={{ fontSize: "2rem", display: "flex" }}
            />
          </h2>
          <h3 className="mb-10 flex items-center text-md leading-[100%] md:text-xl md:leading-[100%] dark:text-neutral-100 justify-center">
            Check your email inbox and follow the instructions to reset your
            password.
          </h3>

          <Button onClick={() => navigate({ pathname: "/login" })}>
            Back to login
          </Button>
        </div>
      ) : (
        <div className="container mb-24 lg:mb-32">
          <div className="text-center">
            <h2 className="mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Reset Password
            </h2>

            <h3 className="mb-10 flex items-center text-md leading-[100%] md:text-xl md:leading-[100%] dark:text-neutral-100 justify-center">
              Enter your email address and we’ll send you a link to reset it.{" "}
            </h3>
          </div>

          <div className="max-w-md mx-auto space-y-6">
            <form
              className="grid grid-cols-1 gap-6"
              onSubmit={sendResetPasswordLink}
            >
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email Address
                </span>
                <Input
                  required
                  type="email"
                  className="mt-1"
                  placeholder="Fill in your email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <ButtonPrimary type="submit">Submit</ButtonPrimary>
              <Button onClick={() => navigate({ pathname: "/login" })}>
                Back to login
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordPage;
