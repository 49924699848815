import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Alert } from "shared/Alert/Alert";

import useConfig from "hooks/useConfig";

const LoginPage = ({ className = "" }) => {
  const { login, loggedIn, loadingLogin } = useAuth();
  const { company } = useConfig();
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [hasUserRequestedLogin, setHasUserRequestedLogin] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    login({ email, password });
    setHasUserRequestedLogin(true);
  };

  useEffect(() => {
    if (loadingLogin || !hasUserRequestedLogin) {
      return;
    }
    if (!loggedIn) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [loadingLogin]);

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);

  const handleEyeClick = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div className={`nc-LoginPage ${className}`} data-nc-id="LoginPage">
      {showAlert && (
        <Alert
          containerClassName="absolute top-2 left-1/2 -translate-x-1/2 max-w-ma pr-4 z-50"
          type="warning"
          showButton={false}
        >
          Invalid login or password
        </Alert>
      )}
      <Helmet>
        <title>Login - {company.name}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Portal Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email Address
              </span>
              <Input
                required
                type="email"
                className="mt-1"
                placeholder="Fill in your email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className="block" style={{ position: "relative" }}>
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link
                  to={"/forgot-password"}
                  state={{ loginUrl: currentUrl }}
                  className="text-sm"
                >
                  Forgot password?
                </Link>
              </span>
              <div>
                <Input
                  required
                  type={showPassword ? "text" : "password"}
                  className="mt-1"
                  placeholder="Fill in your password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  style={{
                    position: "absolute",
                    right: "16px",
                    bottom: "9px",
                  }}
                  onClick={handleEyeClick}
                >
                  <i
                    className={showPassword ? "las la-eye-slash" : "las la-eye"}
                  ></i>
                </button>
              </div>
            </label>
            <ButtonPrimary type="submit" loading={loadingLogin}>
              Continue
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
