import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { SingleDatePicker } from "react-dates";

import moment from "moment";

import useWindowSize from "hooks/useWindowResize";

const utcFormat = "YYYY-MM-DD";

const DateSingleInput = ({
  onChange,
  anchorDirection = "right",
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const [focusedInput, setFocusedInput] = useState(false);
  const [value, setValue] = useState(null);

  const windowSize = useWindowSize();

  const [searchParams] = useSearchParams();
  const queryDate = searchParams.get("date");

  const handleChangeDate = (value) => {
    setValue(value);
    onChange(value?.format(utcFormat));
  };

  useEffect(() => {
    if (queryDate && moment(queryDate, utcFormat, true)) {
      handleChangeDate(moment(queryDate));
    }
  }, []);

  const handleDateFocusChange = (arg) => {
    setFocusedInput(arg.focused);
  };

  const renderInputCheckInDate = () => {
    const focused = focusedInput;
    return (
      <div
        className={`flex w-full relative ${fieldClassName} items-center space-x-3 cursor-pointer ${
          focused ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <i className="las la-calendar text-3xl"></i>
        </div>
        <div className="flex-grow">
          <span className="block xl:text-lg font-semibold">
            {value ? value.format("DD MMM") : "Date"}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {value ? "Date" : `Select Date`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`DateSingleInput relative flex ${className} ${
        !!focusedInput ? "nc-date-focusedInput" : "nc-date-not-focusedInput"
      }`}
      style={{ flex: "1 0 0%" }}
    >
      <div className="absolute inset-0 flex">
        <SingleDatePicker
          date={value}
          onDateChange={handleChangeDate}
          id={"nc-hero-DateSingleInput-startDateId"}
          focused={focusedInput}
          daySize={windowSize.width > 425 ? 56 : undefined}
          orientation={"horizontal"}
          onFocusChange={handleDateFocusChange}
          noBorder
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          anchorDirection={anchorDirection}
          showClearDate
          reopenPickerOnClearDate
        />
      </div>

      {renderInputCheckInDate()}
    </div>
  );
};

export default DateSingleInput;
