import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import root from "react-shadow";

import { API_BASE_URL } from "constants/urls";

import LocationMarker from "components/AnyReactComponent/LocationMarker";
import GoogleMapReact from "google-map-react";
import { Helmet } from "react-helmet";
import { GOOGLE_MAPS_API_KEY } from "constants/keys";

import Avatar from "shared/Avatar/Avatar";

import moment from "moment";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import ModalPhotos from "./ModalPhotos";
import getNumberWithCurrency from "utils/getNumberWithCurrency";

import useConfig from "hooks/useConfig";
import usePortal from "hooks/usePortal";

import Loading from "components/Loading/Loading";
import Timeline from "./Timeline";
import FAQ from "./FAQ";

const loadWidgetScript = () => {
  const script = document.createElement("script");

  const srcUrl =
    process.env.REACT_APP_ENV === "production"
      ? `https://kleesto.ams3.cdn.digitaloceanspaces.com/widget/kleestowidget.js`
      : `${API_BASE_URL}/widget/kleestowidget-${process.env.REACT_APP_ENV}.js`;

  script.src = srcUrl;
  script.async = true;
  script.id = "kleesto-widget-script";
  document.head.appendChild(script);
};

const ProductPage = ({ className = "" }) => {
  const { getProduct } = usePortal();
  const { company, channelId } = useConfig();

  const { id: productId } = useParams();

  const [product, setProduct] = useState({});
  const [loadingProduct, setLoadingProduct] = useState(true);

  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const persons = searchParams.get("persons");

  const fetchProduct = () => {
    getProduct(productId, {
      embed: [
        "fromPrice",
        "location",
        "activeMonths",
        "cancellationPolicy",
        "privacyPolicy",
        "otherPolicies",
        "supplierName",
        "amenities",
        "timelinePlaces",
      ],
    }).then((data) => {
      setProduct(data.product);
      setLoadingProduct(false);
    });
  };

  useEffect(() => {
    fetchProduct();
    loadWidgetScript();
  }, []);

  const {
    name = {},
    capacity,
    summaryDescriptionHtml,
    descriptionHtml = [],
    fromPrice,
    pricingType,
    activeMonths = [],
    amenities = [],
    currency,
    location,
    duration: durationString,
    multiday,
    coverPhoto = [],
    galleryPhotos = [],
    priceIncludes = [],
    priceNotIncludes = [],
    highlights = [],
    cancellationPolicy,
    privacyPolicy,
    otherPolicies = [],
    supplierName,
    timeline,
    timelinePlaces,
    faq,
  } = product;

  const highlightsFormatted =
    highlights[0]?.split("\n").filter((d) => d.length > 0) || [];

  const priceIncludesFormatted =
    priceIncludes[0]?.split("\n").filter((d) => d.length > 0) || [];

  const priceNotIncludesFormatted =
    priceNotIncludes[0]?.split("\n").filter((d) => d.length > 0) || [];

  let locationText;

  if (location) {
    const { region } = location;

    if (region) {
      const { destination } = region;

      if (destination) {
        locationText = `${region.name}, ${destination.title.EN}`;
      } else {
        locationText = region.name;
      }
    }
  }

  const renderTopSection = () => {
    const amenitiesNames = amenities.map((amenity) => amenity.name);

    let durationText = "";

    let duration = Number(durationString);
    // If product is multiday -> Format will be "1 day" or "2 days", etc..
    if (multiday) {
      durationText = `${duration} day${duration > 1 ? "s" : ""}`;
    } else {
      // Product is not multiday -> Format will be "10m" or "1h" or "1h 10m", etc...
      const durationHours = moment()
        .startOf("day")
        .add(duration, "hours")
        .hours();

      if (durationHours >= 1) {
        durationText += `${durationHours}h `;
      }

      const durationMinutes = moment()
        .startOf("day")
        .add(duration, "hours")
        .subtract(durationHours, "hours")
        .minutes();

      if (durationMinutes >= 1) {
        durationText += `${durationMinutes}m`;
      }
    }

    let monthsShortText = "";
    let monthsLongText = "";

    if (activeMonths.length > 0) {
      const min = 3;

      const uniqueActiveMonths = [
        ...new Set(
          activeMonths.map((month) => moment(month, "YYYY-MM").format("MMM"))
        ),
      ];

      if (uniqueActiveMonths.length === 12) {
        monthsLongText = "All Year";
        monthsShortText = "All Year";
      } else {
        monthsLongText = uniqueActiveMonths.join(", ");
        monthsShortText = uniqueActiveMonths.slice(0, min).join(", ");

        if (uniqueActiveMonths.length > min) {
          const remainingMonths = uniqueActiveMonths.length - min;
          monthsShortText += ` + ${remainingMonths} more`;
        }
      }
    }

    return (
      <div className="listingSection__wrap !space-y-6">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {loadingProduct ? <Loading align="left" /> : name.EN}
        </h2>

        {supplierName && (
          <span className="text-primary-700 dark:text-primary-400">
            <span className="mr-2">·</span>
            Provided by {supplierName}
          </span>
        )}

        {locationText && (
          <div className="flex items-center space-x-4">
            <span>
              <i className="las la-map-marker-alt"></i>
              <span className="ml-1">{locationText}</span>
            </span>
          </div>
        )}

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-clock text-2xl"></i>
            <span className="">{loadingProduct ? "-" : durationText}</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-user-friends text-2xl"></i>
            <span className="">
              {loadingProduct
                ? "-"
                : `${capacity} ${capacity !== 1 ? "Persons" : "Person"}`}
            </span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-calendar text-2xl"></i>
            <span className="">
              {loadingProduct ? "-" : monthsShortText || "-"}
            </span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-ticket-alt text-2xl"></i>
            <span className="">
              {loadingProduct || typeof fromPrice === "undefined"
                ? "-"
                : `From ${getNumberWithCurrency(fromPrice, currency)}`}
            </span>
          </div>
        </div>

        {amenitiesNames.length > 0 && (
          <>
            <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 text-neutral-700 dark:text-neutral-300 ">
              {amenitiesNames.map((label) => (
                <div key={label} className="flex items-center space-x-3">
                  <i className="las la-check text-l"></i>
                  <span>{label}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderDescriptionSection = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div className="text-neutral-600 dark:text-neutral-300">
          {loadingProduct ? (
            <Loading align="left" />
          ) : summaryDescriptionHtml ? (
            <root.section>
              <div
                dangerouslySetInnerHTML={{ __html: summaryDescriptionHtml }}
              />
            </root.section>
          ) : (
            descriptionHtml.map((descHtml, index) => (
              <root.section key={index}>
                <div dangerouslySetInnerHTML={{ __html: descHtml }} />
              </root.section>
            ))
          )}
        </div>
      </div>
    );
  };

  const renderHighlitsSection = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Highlights</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-neutral-700 dark:text-neutral-300 ">
          {highlightsFormatted.map((item) => (
            <div key={item} className="flex items-center space-x-3">
              <i className="las la-dot-circle text-2xl"></i>
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTimelineSection = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Timeline</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {<Timeline timeline={timeline} timelinePlaces={timelinePlaces} />}
      </div>
    );
  };

  const renderPriceIncludesSection = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Included In Price</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-neutral-700 dark:text-neutral-300 ">
          {priceIncludesFormatted.map((item) => (
            <div key={item} className="flex items-center space-x-3">
              <i className="las la-check-circle text-2xl"></i>
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPriceNotIncludesSection = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Not Included In Price</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-neutral-700 dark:text-neutral-300 ">
          {priceNotIncludesFormatted.map((item) => (
            <div key={item} className="flex items-center space-x-3">
              <i className="las la-times-circle text-2xl"></i>
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderFAQSection = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Frequently Asked Questions</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div className="space-y-2">
          {faq.map((item, index) => (
            <FAQ key={index} faq={item} />
          ))}
        </div>
      </div>
    );
  };

  // const renderNearbyProducts = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       <div>
  //         <h2 className="text-2xl font-semibold">Nearby Experiences</h2>
  //       </div>
  //       {/* 6 */}
  //       <ListingProductsGrid
  //         products={DEMO_PRODUCTS.slice(0, 4)}
  //         loadingProducts={false}
  //         totalProducts={DEMO_PRODUCTS.length}
  //       />
  //     </div>
  //   );
  // };

  const renderMapLocation = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {location.address}
          </span>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        <div className="aspect-w-5 aspect-h-2">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={10}
              center={{
                lat: location.location.lat,
                lng: location.location.lng,
              }}
            >
              <LocationMarker
                lat={location.location.lat}
                lng={location.location.lng}
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderPolicies = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Additional Information</h2>

        {/* CONTENT */}
        {cancellationPolicy && (
          <>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            <div>
              <h4 className="text-lg font-semibold">
                Cancellation & Refund Policy
              </h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {cancellationPolicy.terms.publicLink ? (
                  <a
                    href={cancellationPolicy.terms.publicLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here to view more details
                  </a>
                ) : (
                  cancellationPolicy.terms.details?.EN
                )}
              </span>
            </div>
          </>
        )}

        {privacyPolicy && (
          <>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            <div>
              <h4 className="text-lg font-semibold">Privacy Policy</h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {privacyPolicy.terms.publicLink ? (
                  <a
                    href={privacyPolicy.terms.publicLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here to view more details
                  </a>
                ) : (
                  privacyPolicy.terms.details?.EN
                )}
              </span>
            </div>
          </>
        )}

        {otherPolicies.map((otherPolicy) => (
          <>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            <div>
              <h4 className="text-lg font-semibold">{otherPolicy.name.EN}</h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {otherPolicy.terms.publicLink ? (
                  <a
                    href={otherPolicy.terms.publicLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here to view more details
                  </a>
                ) : (
                  otherPolicy.terms.details?.EN
                )}
              </span>
            </div>
          </>
        ))}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl hidden lg:flex">
        <div
          className="kleesto-book-calendar"
          data-channel-id={channelId}
          data-product-id={productId}
          data-origin-url={window.location.href}
          data-date={date}
          data-persons={persons}
        />
      </div>
    );
  };

  const photos = loadingProduct
    ? [
        { url: "images/placeholder-large-dark-h.png" },
        { url: "images/placeholder-large-dark-h.png" },
        { url: "images/placeholder-large-dark-h.png" },
      ]
    : coverPhoto.concat(galleryPhotos);

  return (
    <div className={`nc-ProductPage ${className}`} data-nc-id="ProductPage">
      <Helmet>
        <title>
          {loadingProduct ? "Loading" : name.EN} - {company.name}
        </title>
      </Helmet>

      {photos.length > 0 && (
        <ModalPhotos
          photos={photos}
          uniqueClassName="nc-ListingExperiencesDetailPage__modalPhotos"
        />
      )}

      <main className="container relative z-10 mt-11 mb-11 flex flex-col lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderTopSection()}
          {renderDescriptionSection()}
          {highlightsFormatted.length > 0 && renderHighlitsSection()}
          {timeline &&
            timeline[0] &&
            timeline[0].length !== 0 &&
            renderTimelineSection()}
          {priceIncludesFormatted.length > 0 && renderPriceIncludesSection()}
          {priceNotIncludesFormatted.length > 0 &&
            renderPriceNotIncludesSection()}
          {faq && faq.length > 0 && renderFAQSection()}
          {location && renderMapLocation()}
          {(cancellationPolicy || privacyPolicy || otherPolicies.length > 0) &&
            renderPolicies()}
          {/* {renderNearbyProducts()} */}
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>
      </main>
      {/* STICKY FOOTER MOBILE */}
      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
        <div className="container flex items-center justify-between">
          <span className="text-2xl font-semibold">
            {loadingProduct ? (
              <Loading size="small" align="left" />
            ) : typeof fromPrice !== "undefined" ? (
              `${getNumberWithCurrency(fromPrice, currency)}`
            ) : (
              ""
            )}
            {(pricingType === "perperson" || pricingType === "customfixed") &&
              typeof fromPrice !== "undefined" && (
                <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                  /person
                </span>
              )}
          </span>

          <ButtonPrimary
            className="kleesto-book-button custom-branding"
            channelId={channelId}
            productId={productId}
            originUrl={window.location.href}
            date={date}
            persons={persons}
          >
            BOOK NOW
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
