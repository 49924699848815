import React from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";

const Loading = (props) => {
  const { type, color, align, size } = props;

  return (
    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
      <ReactLoading
        className={`loading-${align}`}
        type={type}
        color={color}
        height={size === "small" ? 30 : size === "large" ? 50 : 40}
        width={size === "small" ? 30 : size === "large" ? 50 : 40}
      />
    </div>
  );
};

Loading.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  size: PropTypes.string,
};

Loading.defaultProps = {
  type: "bars",
  color: "black",
  align: "center",
  size: "default",
};

export default Loading;
