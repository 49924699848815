import hexToRGB from "./hexToRgb";

const hexToThemeRgb = (h: string) => {
  const rgb = hexToRGB(h)

  const themeRgb = rgb.replace('rgb(', '').replace(')', '')
  
  return themeRgb
};

export default hexToThemeRgb;
