import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import axios from "axios";

import { API_BASE_URL } from "constants/urls";

import { useLocalStorage } from "./useLocalStorage";
import useConfig from "./useConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [authToken, setAuthToken] = useLocalStorage("authToken", null);
  const [validatingAuthToken, setValidatingAuthToken] = useState(!!authToken);

  const [loggedIn, setLoggedIn] = useState(!!authToken);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const { companySlug, setChannelId } = useConfig();

  const validateAuthToken = async () => {
    axios({
      url: `${API_BASE_URL}/auth/b2bportal`,
      method: "get",
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
      },
    })
      .then(({ data }) => {
        const { channelId } = data;
        setChannelId(channelId);
        setValidatingAuthToken(false);
      })
      .catch((err) => {
        logout();
        setValidatingAuthToken(false);
      });
  };

  const login = async (data) => {
    setLoadingLogin(true);

    axios({
      url: `${API_BASE_URL}/auth/b2bportal/login`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: { ...data, companySlug },
    })
      .then(({ data }) => {
        const { token, channelId } = data;

        setLoggedIn(true);
        setAuthToken(token);
        setChannelId(channelId);
        setLoadingLogin(false);

        navigate(state?.path || "/");
      })
      .catch((err) => {
        setLoadingLogin(false);
      });
  };

  const logout = () => {
    setLoggedIn(false);
    setAuthToken(null);
    setChannelId(null);

    navigate("/login", { replace: true });
  };

  useEffect(() => {
    if (validatingAuthToken) {
      validateAuthToken();
    }
  }, [validatingAuthToken]);

  const value = useMemo(
    () => ({
      loggedIn,
      loadingLogin,
      authToken,
      validatingAuthToken,
      login,
      logout,
    }),
    [loggedIn, loadingLogin, authToken, validatingAuthToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
  return useContext(AuthContext);
}
