import React from "react";
import { usePagination, DOTS } from "./usePagination";

import twFocusClass from "utils/twFocusClass";

import "./Pagination.scss";

const Pagination = ({
  className = "",
  pagination,
  onChange,
  disabled = false,
}) => {
  const { page, size, total } = pagination;

  const paginationRange = usePagination({ page, size, total });

  if (page === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <ul
      className={`pagination-container nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
      // className={classnames("pagination-container", { [className]: className })}
    >
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={
              pageNumber === page
                ? `pagination-item inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-600 text-white ${twFocusClass()}`
                : `pagination-item inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-600 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`
            }
            // className={classnames("pagination-item", {
            //   selected: pageNumber === page,
            // })}

            onClick={() =>
              !disabled && onChange({ ...pagination, page: pageNumber })
            }
          >
            {pageNumber}
          </li>
        );
      })}
    </ul>
  );
};

export default Pagination;
