console.log({ Environment: process.env.REACT_APP_ENV });

switch (process.env.REACT_APP_ENV) {
  case "production":
    module.exports = {
      GOOGLE_MAPS_API_KEY: "AIzaSyCpRqoSYGeve_21XMBSHPeq45TiIvSikDI",
    };
    break;

  case "staging":
    module.exports = {
      GOOGLE_MAPS_API_KEY: "AIzaSyCdl6lW41-lzzU_yoJhkcMM79qnf6OvBW0",
    };
    break;

  default:
    module.exports = {
      GOOGLE_MAPS_API_KEY: "AIzaSyCdl6lW41-lzzU_yoJhkcMM79qnf6OvBW0",
    };
}
