import React, { useState } from "react";

function FAQ({ faq }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`nc-FlightCardgroup p-6 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 cursor-pointer`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={`sm:pr-20 relative`} data-nc-id="FlightCard">
        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-8 h-8 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
        >
          <i className="text-lg las la-angle-down"></i>
        </span>
        <span className={isOpen ? "font-semibold" : undefined}>
          {faq.question}
        </span>
      </div>
      {isOpen ? (
        <div className="cursor-default">
          <div className="w-140 border-b border-neutral-200 dark:border-neutral-700" />
          <div className="pt-6">{faq.answer}</div>
        </div>
      ) : null}
    </div>
  );
}

export default FAQ;
