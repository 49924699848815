import React from "react";
import { Link } from "react-router-dom";

import useConfig from "hooks/useConfig";

export interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className = "" }) => {
  const { company } = useConfig();

  const img = company.logo[0].url;

  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-600 ${className}`}
    >
      {/* <LogoSvgLight /> */}
      {/* <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img className={`block max-h-12`} src={img} alt="company-logo" />
      ) : (
        "Company Logo"
      )}
    </Link>
  );
};

export default Logo;
