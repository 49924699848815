import { useEffect, useState } from "react";
import SectionHero from "components/SectionHero/SectionHero";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";

import useConfig from "hooks/useConfig";

import ListingProductsGrid from "containers/ListingExperiencesPage/ListingProductsGrid";
import usePortal from "hooks/usePortal";
import SectionSliderItems from "components/SectionSliderItems/SectionSliderItems";

function HomePage() {
  const { company } = useConfig();

  const {
    destinations,
    loadingDestinations,
    categories,
    loadingCategories,
    getProducts,
  } = usePortal();

  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const [pagination, setPagination] = useState({ page: 1, size: 12, total: 0 });

  const fetchProducts = () => {
    setLoadingProducts(true);

    getProducts({
      page: pagination.page - 1,
      size: pagination.size,
      fields: ["name", "currency", "profilePhoto", "galleryPhotos"],
      embed: [
        "fromPrice",
        "location",
        "location.region",
        "region.destination",
        "supplierName",
      ],
    }).then((data) => {
      setProducts(data.products);
      setTotalProducts(data.totalProducts);
      setPagination({ ...pagination, total: data.totalProducts });

      setLoadingProducts(false);
    });
  };

  useEffect(() => {
    fetchProducts();
  }, [pagination.page, pagination.size]);

  const handleChangePagination = (pagination) => {
    setPagination(pagination);
  };

  return (
    <div className="nc-HomePage relative overflow-hidden">
      <Helmet>
        <title>Home - {company.name}</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <SectionHero
          className="pt-10 pb-16"
          centerTitle
          title={company.name}
          subtitle={company.phrase}
        />

        <SectionSliderItems
          heading="Destinations"
          subHeading="Discover the most popular destinations"
          sliderStyle="style1"
          items={destinations}
          loadingItems={loadingDestinations}
          itemType="destination"
          uniqueClassName="PageHome_s1"
        />

        <SectionSliderItems
          heading="Categories"
          subHeading="Find experiences based on categories"
          sliderStyle="style1"
          items={categories}
          loadingItems={loadingCategories}
          itemType="category"
          uniqueClassName="PageHome_s1"
        />

        {/* SECTION */}
        <ListingProductsGrid
          heading="Experiences"
          subHeading="Discover the most popular experiences"
          sliderStyle="style1"
          // className="pb-24 lg:pb-32"
          products={products}
          totalProducts={totalProducts}
          loadingProducts={loadingProducts}
          pagination={pagination}
          onChangePagination={handleChangePagination}
        />
      </div>
    </div>
  );
}

export default HomePage;
