import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import useConfig from "hooks/useConfig";

export interface SocialsList1Props {
  className?: string;
}

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const { company } = useConfig();

  const socials: SocialType[] = [];

  if (company.links?.facebook) {
    socials.push({
      name: "Facebook",
      icon: "lab la-facebook-square",
      href: company.links.facebook,
    });
  }

  if (company.links?.twitter) {
    socials.push({
      name: "Twitter",
      icon: "lab la-twitter",
      href: company.links.twitter,
    });
  }

  if (company.links?.youtube) {
    socials.push({
      name: "YouTube",
      icon: "lab la-youtube",
      href: company.links.youtube,
    });
  }

  if (company.links?.instagram) {
    socials.push({
      name: "Instagram",
      icon: "lab la-instagram",
      href: company.links.instagram,
    });
  }

  // if (company.links?.linkedin) {
  //   socials.push({
  //     name: "LinkedIn",
  //     icon: "lab la-linkedin",
  //     href: company.links.linkedin,
  //   });
  // }

  if (company.links?.pinterest) {
    socials.push({
      name: "Pinterest",
      icon: "lab la-pinterest",
      href: company.links.pinterest,
    });
  }

  if (company.links?.tripadvisor) {
    socials.push({
      name: "Tripadvisor",
      icon: "lab la-tripadvisor",
      href: company.links.tripadvisor,
    });
  }

  // if (company.links?.blog) {
  //   socials.push({
  //     name: "Blog",
  //     icon: "lab la-table",
  //     href: company.links.blog,
  //   });
  // }

  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        target="_blank"
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <i className={item.icon}></i>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
