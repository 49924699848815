import React, { FC } from "react";
import { ProductType, PaginationType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import Heading from "components/Heading/Heading";
import ProductCard from "components/ProductCard/ProductCard";

import Loading from "components/Loading/Loading";

import ProductFilters from "./ProductFilters";

export interface ListingProductsGridProps {
  products: ProductType[];
  loadingProducts: boolean;
  totalProducts: number;
  className?: string;
  heading?: string;
  subHeading?: string;
  pagination?: PaginationType;
  onChangePagination?: void;
  sliderStyle?: string;
  showFilters?: boolean;
  onChangeFilter?: void;
}

const ListingProductsGrid: FC<ListingProductsGridProps> = ({
  heading,
  subHeading,
  products,
  loadingProducts,
  totalProducts,
  className = "",
  pagination,
  onChangePagination,
  sliderStyle = "style1",
  showFilters = false,
  onChangeFilter,
}) => {
  return (
    <div
      className={`nc-ListingProductsGrid ${className}`}
      data-nc-id="ListingProductsGrid"
    >
      {heading && (
        <Heading
          isCenter={sliderStyle === "style2"}
          desc={subHeading ? subHeading : ""}
        >
          {heading}
        </Heading>
      )}

      {showFilters && (
        <div className="mb-8 lg:mb-11">
          <ProductFilters onChangeFilter={onChangeFilter} />
        </div>
      )}

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {loadingProducts
          ? Array.from({ length: pagination?.size || 8 }, (_, i) => i + 1).map(
              (index) => (
                <ProductCard
                  key={index}
                  loading
                  size="default"
                  ratioClass="aspect-w-2 aspect-h-1"
                />
              )
            )
          : products.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                size="default"
                ratioClass="aspect-w-2 aspect-h-1"
              />
            ))}
      </div>

      {pagination && (
        <div className="flex mt-16 justify-center items-center">
          <Pagination
            className={className}
            pagination={pagination}
            disabled={loadingProducts}
            onChange={onChangePagination}
          />
        </div>
      )}
    </div>
  );
};

export default ListingProductsGrid;
