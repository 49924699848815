import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import NextPrev from "shared/NextPrev/NextPrev";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Glide from "@glidejs/glide";
import NcImage from "shared/NcImage/NcImage";
import useNcId from "hooks/useNcId";

import { PhotoType } from "data/types";

export interface ModalPhotosProps {
  photos: PhotoType[];
  initFocus?: number;
  uniqueClassName: string;
}

const ModalPhotos: FC<ModalPhotosProps> = ({
  photos,
  uniqueClassName = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const UNIQUE_CLASS =
    `ModalPhotos_single_glide_${uniqueClassName}` + useNcId();
  let completeButtonRef = useRef(null);

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      // @ts-ignore
      direction:
        document.querySelector("html")?.getAttribute("dir") === "rtl"
          ? "rtl"
          : "ltr",
      gap: 10,
      perView: 1,
      startAt: openFocusIndex,
    });
  }, [UNIQUE_CLASS, openFocusIndex]);

  useEffect(() => {
    if (!isOpen) return;
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, isOpen, UNIQUE_CLASS]);

  const renderSlider = () => {
    return (
      <div
        className={`modalPhotos-single-gallery ${UNIQUE_CLASS} group relative flex flex-col z-50 w-full h-full`}
      >
        <div
          className="controls_nav glide__bullets mt-8 mb-5"
          data-glide-el="controls[nav]"
        >
          {photos.map((_: any, index: number) => (
            <div key={index} className="text-center hidden">
              <span className="text-3xl font-semibold"> {index + 1}</span>
              <span> / {photos.length}</span>
            </div>
          ))}
        </div>

        <div
          className="glide__track max-h-full h-full relative z-50"
          data-glide-el="track"
        >
          <ul className="glide__slides h-full">
            {photos.map((photo: any, index: number) => (
              <li className="glide__slide relative h-full" key={index}>
                <NcImage
                  src={photo.url}
                  containerClassName=" w-full h-full"
                  className="absolute object-contain w-full max-h-screen"
                />
              </li>
            ))}
          </ul>
        </div>
        {/*  */}
        <div className="xl:absolute z-20 xl:-inset-x-20 max-w-6xl my-2 mx-auto top-full xl:top-1/2 transform xl:-translate-y-1/2 flex xl:justify-between glide__arrows">
          <NextPrev
            onlyPrev
            className="mr-1.5"
            btnClassName="w-8 h-8 sm:w-10 sm:h-10 "
          />
          <NextPrev
            onlyNext
            className="ml-1.5"
            btnClassName="w-8 h-8 sm:w-10 sm:h-10 "
          />
        </div>
      </div>
    );
  };

  const renderModalPhotos = () => {
    return (
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-1 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={photos[0].url}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {photos
              .filter((_: any, i: number) => i >= 1 && i < 3)
              .map((item: any, index: number) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 2 ? "block" : ""
                  }`}
                >
                  <NcImage
                    containerClassName="aspect-w-4 aspect-h-3"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={item.url || ""}
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(index + 1)}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>

        <Dialog
          initialFocus={completeButtonRef}
          as="div"
          className="fixed inset-0 z-max overflow-y-auto dark bg-neutral-800 text-neutral-200 hiddenScrollbar"
          onClose={handleCloseModal}
          open={isOpen}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-white dark:bg-neutral-800" />
            <div
              ref={completeButtonRef}
              className="absolute left-2 top-2 md:top-4 md:left-4 z-max"
            >
              <ButtonClose
                className=" sm:w-11 sm:h-11"
                onClick={handleCloseModal}
              />
            </div>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block w-full max-w-5xl py-8 h-screen align-middle mx-auto">
              {renderSlider()}
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  return renderModalPhotos();
};

export default ModalPhotos;
