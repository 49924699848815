import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { ProductType } from "data/types";
import {
  Link,
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import getNumberWithCurrency from "utils/getNumberWithCurrency";
import getRandomId from "utils/getRandomId";

const fakeProduct: ProductType = {
  _id: getRandomId(),
  name: { EN: "" },
  currency: "EUR",
  profilePhoto: [{ url: "images/placeholder-large-dark-h.png" }],
  galleryPhotos: [],
};

export interface ProductCardProps {
  size?: "default" | "small";
  className?: string;
  ratioClass?: string;
  product?: ProductType;
  loading?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  size = "default",
  className = "",
  ratioClass = "aspect-w-3 aspect-h-3",
  product = fakeProduct,
  loading = false,
}) => {
  const navigate = useNavigate();

  const {
    _id,
    name,
    fromPrice,
    currency,
    location,
    profilePhoto,
    galleryPhotos,
    supplierName,
  } = product;

  const [searchParams] = useSearchParams();

  const prepareSearchParams = () => {
    const date = searchParams.get("date");
    const persons = searchParams.get("persons");

    const params = {
      date,
      persons,
    };

    // Clear undefined params
    for (const key in params) {
      if (params[key] === undefined || params[key] === null) {
        delete params[key];
      }
    }

    return createSearchParams(params).toString();
  };

  const handleNavigate = () => {
    navigate({
      pathname: `/products/${product._id}`,
      search: prepareSearchParams(),
    });
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden will-change-transform">
        <GallerySlider
          onClickImage={loading ? undefined : handleNavigate}
          uniqueID={`ExperiencesCard_${_id}`}
          ratioClass={ratioClass}
          galleryImgs={profilePhoto
            .concat(galleryPhotos)
            .map((photo) => photo.url)}
        />
      </div>
    );
  };

  let locationText: string;

  if (location) {
    const { region } = location;

    if (region) {
      const { destination } = region;

      if (destination) {
        locationText = `${region.name}, ${destination.title.EN}`;
      } else {
        locationText = region.name;
      }
    }
  }

  const renderContent = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && locationText && (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                <span className="">{locationText}</span>
              </>
            )}
          </div>

          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-base" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{name.EN}</span>
            </h2>
          </div>

          {supplierName && (
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              <span>Provided by {supplierName}</span>
            </div>
          )}
        </div>
        <div className="border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          {typeof fromPrice !== "undefined" && (
            <>
              <span className="text-base">
                From{" "}
                <span className="font-semibold">
                  {getNumberWithCurrency(fromPrice, currency)}
                </span>
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ProductCard group relative ${className}`}
      data-nc-id="ProductCard"
    >
      {renderSliderGallery()}

      {loading ? (
        renderContent()
      ) : (
        <Link
          to={{
            pathname: `/products/${product._id}`,
            search: prepareSearchParams(),
          }}
        >
          {renderContent()}
        </Link>
      )}
    </div>
  );
};

export default ProductCard;
