export default function getNumberWithCurrency(
  number: number,
  currency: string
) {
  if (currency === "EUR") {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency,
    }).format(number);
  } else if (currency === "CAD") {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency,
    }).format(number);
  } else if (currency === "USD") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    }).format(number);
  } else {
    return number;
  }
}
