import { Routes, Route } from "react-router-dom";

import LoginPage from "containers/LoginPage";
import ForgotPasswordPage from "containers/ForgotPasswordPage";
import HomePage from "containers/HomePage";
import SearchPage from "containers/SearchPage";
import ProductPage from "containers/ProductPage";
import Page404 from "containers/Page404";

import Footer from "shared/Footer/Footer";
import Header from "shared/Header/Header";
import { ProtectedRoute } from "./ProtectedRoute";

import useAuth from "hooks/useAuth";

const B2BPortalRoutes = () => {
  const { validatingAuthToken } = useAuth();

  if (validatingAuthToken) {
    return <></>;
  }

  return (
    <>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/search"
          element={
            <ProtectedRoute>
              <SearchPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/products/:id"
          element={
            <ProtectedRoute>
              <ProductPage />
            </ProtectedRoute>
          }
        />

        <Route path="/login" element={<LoginPage />} />

        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </>
  );
};

export default B2BPortalRoutes;
