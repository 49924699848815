import React, { Fragment, useState } from "react";

import ISO6391 from "iso-639-1";
import _ from "lodash";

import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import usePortal from "hooks/usePortal";

const timeOptions = [
  {
    key: "morning",
    name: "Morning",
    description: "Start before 12pm",
    maxStartTime: "12:00",
  },
  {
    key: "evening",
    name: "Evening",
    description: "Start after 5pm",
    minStartTime: "17:00",
  },
  {
    key: "afternoon",
    name: "Afternoon",
    description: "Start after 12pm",
    minStartTime: "12:00",
    maxStartTime: "17:00",
  },
];

const capacityOptions = [
  {
    key: "small",
    name: "10+ Persons",
    minCapacity: "10",
  },
  {
    key: "large",
    name: "50+ Persons",
    minCapacity: "50",
  },
  {
    key: "medium",
    name: "20+ Persons",
    minCapacity: "20",
  },

  {
    key: "xlarge",
    name: "100+ Persons",
    minCapacity: "100",
  },
];

const renderXClear = (onClick) => {
  return (
    <span
      className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick();
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 w-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  );
};

const ProductFilters = ({ onChangeFilter }) => {
  const { filters } = usePortal();
  const { amenities: allAmenities = [], languages: allLanguages = [] } =
    filters;

  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const [minPriceSelected, setMinPriceSelected] = useState(undefined);
  const [maxPriceSelected, setMaxPriceSelected] = useState(undefined);
  const [timeOptionSelected, setTimeOptionSelected] = useState(undefined);
  const [capacityOptionSelected, setCapacityOptionSelected] =
    useState(undefined);
  const [amenitiesIdsSelected, setAmenitiesIdsSelected] = useState([]);
  const [languagesSelected, setLanguagesSelected] = useState([]);

  const handleApplyPriceOption = (minPrice, maxPrice) => {
    setMinPriceSelected(minPrice);
    setMaxPriceSelected(maxPrice);
    onChangeFilter({ minPrice, maxPrice });
  };

  const handleApplyTimeOption = (value) => {
    const { minStartTime, maxStartTime } = value
      ? timeOptions.find((option) => option.key === value)
      : {};

    setTimeOptionSelected(value);
    onChangeFilter({ minStartTime, maxStartTime });
  };

  const handleApplyCapacityOption = (value) => {
    const { minCapacity } = value
      ? capacityOptions.find((option) => option.key === value)
      : {};

    setCapacityOptionSelected(value);
    onChangeFilter({ minCapacity });
  };

  const handleApplyAmenitiesIds = (value) => {
    setAmenitiesIdsSelected(value);
    onChangeFilter({ amenitiesIds: value });
  };

  const handleApplyLanguages = (value) => {
    setLanguagesSelected(value);
    onChangeFilter({ languages: value });
  };

  const handleClearAllFilters = (value) => {
    setMinPriceSelected(undefined);
    setMaxPriceSelected(undefined);
    setTimeOptionSelected(undefined);
    setCapacityOptionSelected(undefined);
    setLanguagesSelected([]);
    setAmenitiesIdsSelected([]);

    onChangeFilter({
      minStartTime: undefined,
      maxStartTime: undefined,
      minPrice: undefined,
      maxPrice: undefined,
      minCapacity: undefined,
      amenitiesIds: [],
      languages: [],
    });
  };

  const handleApplyAllFilters = () => {
    const { minStartTime, maxStartTime } = timeOptionSelected
      ? timeOptions.find((option) => option.key === timeOptionSelected)
      : {};

    const { minCapacity } = capacityOptionSelected
      ? capacityOptions.find((option) => option.key === capacityOptionSelected)
      : {};

    onChangeFilter({
      minStartTime,
      maxStartTime,
      minPrice: minPriceSelected,
      maxPrice: maxPriceSelected,
      minCapacity,
      amenitiesIds: amenitiesIdsSelected,
      languages: languagesSelected,
    });
  };

  const renderTimeOptionsFilter = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border ${
                timeOptionSelected
                  ? "border-primary-500 text-primary-700"
                  : "border-neutral-300 dark:border-neutral-700"
              } focus:outline-none ${open ? "!border-primary-500 " : ""}`}
            >
              <span>Time of Day</span>
              <i className="las la-angle-down ml-2"></i>
              {timeOptionSelected &&
                renderXClear(() => {
                  handleApplyTimeOption(undefined);
                  close();
                })}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div className="grid grid-cols-2 gap-8">
                      {_.chunk(timeOptions, 2).map((chunk, index) => (
                        <div key={index} className="flex flex-col space-y-5">
                          {chunk.map((option) => (
                            <div
                              key={option.key}
                              className="flex flex-col space-y-5"
                            >
                              <Checkbox
                                onChange={(checked) =>
                                  setTimeOptionSelected(
                                    checked ? option.key : undefined
                                  )
                                }
                                checked={option.key === timeOptionSelected}
                                name={option.name}
                                label={option.name}
                                subLabel={option.description}
                              />
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        handleApplyTimeOption(undefined);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        handleApplyTimeOption(timeOptionSelected);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderCapacityFilter = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border ${
                capacityOptionSelected
                  ? "border-primary-500 text-primary-700"
                  : "border-neutral-300 dark:border-neutral-700"
              } focus:outline-none ${open ? "!border-primary-500 " : ""}`}
            >
              <span>Capacity</span>
              <i className="las la-angle-down ml-2"></i>
              {capacityOptionSelected &&
                renderXClear(() => {
                  handleApplyCapacityOption(undefined);
                  close();
                })}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div className="grid grid-cols-2 gap-8">
                      {_.chunk(capacityOptions, 2).map((chunk, index) => (
                        <div key={index} className="flex flex-col space-y-5">
                          {chunk.map((option) => (
                            <div
                              key={option.key}
                              className="flex flex-col space-y-5"
                            >
                              <Checkbox
                                onChange={(checked) =>
                                  setCapacityOptionSelected(
                                    checked ? option.key : undefined
                                  )
                                }
                                checked={option.key === capacityOptionSelected}
                                name={option.name}
                                label={option.name}
                              />
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        handleApplyCapacityOption(undefined);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        handleApplyCapacityOption(capacityOptionSelected);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderLanguagesFilters = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border ${
                languagesSelected.length > 0
                  ? "border-primary-500 text-primary-700"
                  : "border-neutral-300 dark:border-neutral-700"
              } focus:outline-none ${open ? "!border-primary-500 " : ""}`}
            >
              <span>Languages</span>
              <i className="las la-angle-down ml-2"></i>
              {languagesSelected.length > 0 &&
                renderXClear(() => {
                  handleApplyLanguages([]);
                  close();
                })}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div className="grid grid-cols-2 gap-8">
                      {_.chunk(allLanguages, allLanguages.length / 2).map(
                        (chunk, index) => (
                          <div key={index} className="flex flex-col space-y-5">
                            {chunk.map((lang) => (
                              <div key={lang} className="">
                                <Checkbox
                                  onChange={(checked) => {
                                    if (checked) {
                                      setLanguagesSelected([
                                        ...languagesSelected,
                                        lang,
                                      ]);
                                    } else {
                                      setLanguagesSelected(
                                        languagesSelected.filter(
                                          (langSelected) =>
                                            langSelected !== lang
                                        )
                                      );
                                    }
                                  }}
                                  checked={languagesSelected.includes(lang)}
                                  name={lang}
                                  label={ISO6391.getName(lang)}
                                />
                              </div>
                            ))}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        handleApplyLanguages([]);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        handleApplyLanguages(languagesSelected);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderAmenitiesFilters = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border ${
                amenitiesIdsSelected.length > 0
                  ? "border-primary-500 text-primary-700"
                  : "border-neutral-300 dark:border-neutral-700"
              } focus:outline-none ${open ? "!border-primary-500 " : ""}`}
            >
              <span>More Filters</span>
              <i className="las la-angle-down ml-2"></i>
              {amenitiesIdsSelected.length > 0 &&
                renderXClear(() => {
                  handleApplyAmenitiesIds([]);
                  close();
                })}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div className="grid grid-cols-2 gap-8">
                      {_.chunk(allAmenities, allAmenities.length / 2).map(
                        (chunk, index) => (
                          <div key={index} className="flex flex-col space-y-5">
                            {chunk.map((amenity) => (
                              <div key={amenity._id} className="">
                                <Checkbox
                                  onChange={(checked) => {
                                    if (checked) {
                                      setAmenitiesIdsSelected([
                                        ...amenitiesIdsSelected,
                                        amenity._id,
                                      ]);
                                    } else {
                                      setAmenitiesIdsSelected(
                                        amenitiesIdsSelected.filter(
                                          (tagSelected) =>
                                            tagSelected !== amenity._id
                                        )
                                      );
                                    }
                                  }}
                                  checked={amenitiesIdsSelected.includes(
                                    amenity._id
                                  )}
                                  name={amenity.name}
                                  label={amenity.name}
                                />
                              </div>
                            ))}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        handleApplyAmenitiesIds([]);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        handleApplyAmenitiesIds(amenitiesIdsSelected);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderPriceRangeFilter = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border ${
                typeof minPriceSelected !== "undefined" ||
                typeof maxPriceSelected !== "undefined"
                  ? "border-primary-500 text-primary-700"
                  : "border-neutral-300 dark:border-neutral-700"
              } focus:outline-none ${open ? "!border-primary-500 " : ""}`}
            >
              <span>Price Range</span>
              <i className="las la-angle-down ml-2"></i>

              {(typeof minPriceSelected !== "undefined" ||
                typeof maxPriceSelected !== "undefined") &&
                renderXClear(() => {
                  handleApplyPriceOption(undefined, undefined);
                  close();
                })}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min Price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            {/* <span className="text-neutral-500 sm:text-sm">
                              $
                            </span> */}
                          </div>
                          <input
                            type="number"
                            name="minPrice"
                            id="minPrice"
                            min={1}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={minPriceSelected}
                            onChange={(e) =>
                              setMinPriceSelected(
                                e.target.value === ""
                                  ? undefined
                                  : Number(e.target.value) <= 0
                                  ? "0"
                                  : Number(e.target.value) >
                                    Number(maxPriceSelected)
                                  ? maxPriceSelected
                                  : Number(e.target.value).toFixed(0)
                              )
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max Price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            {/* <span className="text-neutral-500 sm:text-sm">
                              $
                            </span> */}
                          </div>
                          <input
                            type="number"
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={maxPriceSelected}
                            onChange={(e) => {
                              setMaxPriceSelected(
                                e.target.value === ""
                                  ? undefined
                                  : Number(e.target.value) < 0
                                  ? "0"
                                  : Number(e.target.value) <
                                    Number(minPriceSelected)
                                  ? minPriceSelected
                                  : Number(e.target.value).toFixed(0)
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        handleApplyPriceOption(undefined, undefined);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        handleApplyPriceOption(
                          minPriceSelected,
                          maxPriceSelected
                        );
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabMobileFilter = () => {
    let totalFilters = 0;

    if (typeof minPriceSelected !== "undefined") {
      totalFilters += 1;
    }

    if (typeof maxPriceSelected !== "undefined") {
      totalFilters += 1;
    }

    if (typeof timeOptionSelected !== "undefined") {
      totalFilters += 1;
    }

    if (typeof capacityOptionSelected !== "undefined") {
      totalFilters += 1;
    }

    if (amenitiesIdsSelected.length > 0) {
      totalFilters += 1;
    }

    if (languagesSelected.length > 0) {
      totalFilters += 1;
    }

    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border cursor-pointer ${
            totalFilters >= 1
              ? "border-primary-500 text-primary-700"
              : "border-neutral-300 dark:border-neutral-700"
          } focus:outline-none ${
            totalFilters >= 1 ? "!border-primary-500 " : ""
          }`}
          onClick={openModalMoreFilter}
        >
          <span>
            <span className="hidden sm:inline">Experiences</span> Filters{" "}
            {totalFilters >= 1 && `(${totalFilters})`}
          </span>
          {totalFilters >= 1 &&
            renderXClear(() => {
              handleClearAllFilters();
            })}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Experiences Filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Price Range</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min Price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <input
                                    type="number"
                                    name="minPrice"
                                    id="minPrice"
                                    min={1}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={minPriceSelected}
                                    onChange={(e) =>
                                      setMinPriceSelected(
                                        e.target.value === ""
                                          ? undefined
                                          : Number(e.target.value) <= 0
                                          ? "0"
                                          : Number(e.target.value) >
                                            Number(maxPriceSelected)
                                          ? maxPriceSelected
                                          : Number(e.target.value).toFixed(0)
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max Price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <input
                                    type="number"
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={maxPriceSelected}
                                    onChange={(e) => {
                                      setMaxPriceSelected(
                                        e.target.value === ""
                                          ? undefined
                                          : Number(e.target.value) < 0
                                          ? "0"
                                          : Number(e.target.value) <
                                            Number(minPriceSelected)
                                          ? minPriceSelected
                                          : Number(e.target.value).toFixed(0)
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="py-7">
                        <h3 className="text-xl font-medium">Time of Day</h3>
                        <div className="mt-6 relative">
                          <div className="grid grid-cols-2 gap-8">
                            {timeOptions.map((option) => (
                              <div
                                key={option.key}
                                className="flex flex-col space-y-5"
                              >
                                <Checkbox
                                  onChange={(checked) =>
                                    setTimeOptionSelected(
                                      checked ? option.key : undefined
                                    )
                                  }
                                  checked={option.key === timeOptionSelected}
                                  name={option.name}
                                  label={option.name}
                                  subLabel={option.description}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="py-7">
                        <h3 className="text-xl font-medium">Capacity</h3>
                        <div className="mt-6 relative">
                          <div className="grid grid-cols-2 gap-8">
                            {capacityOptions.map((option) => (
                              <div
                                key={option.key}
                                className="flex flex-col space-y-5"
                              >
                                <Checkbox
                                  onChange={(checked) =>
                                    setCapacityOptionSelected(
                                      checked ? option.key : undefined
                                    )
                                  }
                                  checked={
                                    option.key === capacityOptionSelected
                                  }
                                  name={option.name}
                                  label={option.name}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      {allLanguages.length > 0 && (
                        <div className="py-7">
                          <h3 className="text-xl font-medium">Languages</h3>
                          <div className="mt-6 relative">
                            <div className="grid grid-cols-2 gap-8">
                              {allLanguages.map((lang) => (
                                <div
                                  key={lang}
                                  className="flex flex-col space-y-5"
                                >
                                  <Checkbox
                                    onChange={(checked) => {
                                      if (checked) {
                                        setLanguagesSelected([
                                          ...languagesSelected,
                                          lang,
                                        ]);
                                      } else {
                                        setLanguagesSelected(
                                          languagesSelected.filter(
                                            (langSelected) =>
                                              langSelected !== lang
                                          )
                                        );
                                      }
                                    }}
                                    checked={languagesSelected.includes(lang)}
                                    name={lang}
                                    label={ISO6391.getName(lang)}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {allAmenities.length > 0 && (
                        <div className="py-7">
                          <h3 className="text-xl font-medium">More Filters</h3>
                          <div className="mt-6 relative">
                            <div className="grid grid-cols-2 gap-8">
                              {allAmenities.map((amenity) => (
                                <div
                                  key={amenity._id}
                                  className="flex flex-col space-y-5"
                                >
                                  <Checkbox
                                    onChange={(checked) => {
                                      if (checked) {
                                        setAmenitiesIdsSelected([
                                          ...amenitiesIdsSelected,
                                          amenity._id,
                                        ]);
                                      } else {
                                        setAmenitiesIdsSelected(
                                          amenitiesIdsSelected.filter(
                                            (tagSelected) =>
                                              tagSelected !== amenity._id
                                          )
                                        );
                                      }
                                    }}
                                    checked={amenitiesIdsSelected.includes(
                                      amenity._id
                                    )}
                                    name={amenity.name}
                                    label={amenity.name}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        handleClearAllFilters();
                        closeModalMoreFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        handleApplyAllFilters();
                        closeModalMoreFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div>
      <div className="flex lg:space-x-4">
        <div className="hidden lg:flex space-x-4">
          {/* {renderPriceRangeFilter()} */}
          {renderTimeOptionsFilter()}
          {renderCapacityFilter()}
          {allLanguages.length > 0 && renderLanguagesFilters()}
          {allAmenities.length > 0 && renderAmenitiesFilters()}
        </div>

        <div className="flex lg:hidden space-x-4">
          {renderTabMobileFilter()}
        </div>
      </div>
    </div>
  );
};

export default ProductFilters;
