console.log({ Environment: process.env.REACT_APP_ENV });

switch (process.env.REACT_APP_ENV) {
  case "production":
    module.exports = {
      API_BASE_URL: "https://api.kleesto.com",
    };
    break;

  case "staging":
    module.exports = {
      API_BASE_URL: "https://staging.api.kleesto.com",
    };
    break;

  default:
    module.exports = {
      API_BASE_URL: "https://staging.api.kleesto.com",
    };
}
