import { useState, useMemo, createContext, useContext, useEffect } from "react";
import { parseDomain } from "parse-domain";

import axios from "axios";

import { API_BASE_URL } from "constants/urls";
import hexToThemeRgb from "utils/hexToThemeRgb";

const ConfigContext = createContext();

function setCompanyBrandColors(brandColors) {
  const colorWeights = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

  colorWeights.forEach((colorWeight) => {
    if (brandColors.primary) {
      document.documentElement.style.setProperty(
        `--c-primary-${colorWeight}`,
        hexToThemeRgb(brandColors.primary)
      );
    }

    // TODO: Update Secondary color weights
    // document.documentElement.style.setProperty(
    //   `--c-secondary-${colorWeight}`,
    //   hexToThemeRgb(brandColors.secondary)
    // );

    // TODO: Update neutral color weights
    // document.documentElement.style.setProperty(
    //   `--c-neutral-${colorWeight}`,
    //   hexToThemeRgb(brandColors.neutral)
    // );
  });
}

const getSubdomainByUrl = () => {
  const windowURL = window.location.host;
  const parseResult = parseDomain(windowURL);

  const { domain, subDomains, topLevelDomains } = parseResult;

  let subdomain = domain;
  if (Array.isArray(subDomains) && subDomains.length > 0) {
    subdomain = `${subDomains
      .filter((s) => s !== "www")
      .join(".")}.${domain}${topLevelDomains.map(
      (topLevel) => `.${topLevel}`
    )}`;
  }

  return subdomain;
};

export const ConfigProvider = ({ children }) => {
  const [appId, setAppId] = useState(undefined);
  const [appBasePath, setAppBasePath] = useState(undefined);
  const [channelId, setChannelId] = useState(undefined);

  const [company, setCompany] = useState(undefined);
  const [companyLoaded, setCompanyLoaded] = useState(false);

  const [appLoaded, setAppLoaded] = useState(false);

  const initApp = () => {
    // Check if identifier is app identifier or a company slug
    // e.g with custom domain -> {subdomain}/{appId}
    // e.g without custom domain -> subdomain.kleesto.com/{companySlug}/{appId}

    const [_, key] = window.location.pathname.split("/");

    const keyIsAppId = key === "b2bportal";

    if (keyIsAppId) {
      setAppId(key);
      setAppBasePath(key);

      const subdomain = getSubdomainByUrl();

      axios({
        url: `${API_BASE_URL}/api/public/company`,
        headers: { Accept: "application/json" },
        method: "get",
        params: { subdomain },
      }).then(({ data }) => {
        const { company } = data;
        setCompany(company);
      });
    } else {
      const [_, companySlug, appId] = window.location.pathname.split("/");

      setAppId(appId);
      setAppBasePath(`/${companySlug}/${appId}`);

      axios({
        url: `${API_BASE_URL}/api/public/company`,
        headers: { Accept: "application/json" },
        method: "get",
        params: { slug: companySlug },
      }).then(({ data }) => {
        const { company } = data;
        setCompany(company);
      });
    }
  };

  useEffect(() => {
    if (company) {
      if (company.brandColors) {
        setCompanyBrandColors(company.brandColors);
      }

      setCompanyLoaded(true);
    }
  }, [company]);

  useEffect(() => {
    if (companyLoaded) {
      setAppLoaded(true);
    }
  }, [companyLoaded]);

  useEffect(() => {
    initApp();
  }, []);

  const value = useMemo(
    () => ({
      appLoaded,
      appId,
      appBasePath,
      channelId,
      company,
      companySlug: company?.slug,
      setChannelId,
    }),
    [appLoaded, appId, appBasePath, channelId, company, setChannelId]
  );

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export default function useConfig() {
  return useContext(ConfigContext);
}
