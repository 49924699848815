import React, { useState } from "react";

import { createSearchParams, useNavigate } from "react-router-dom";

import DestinationPickerInput from "./components/DestinationPickerInput";
import PersonsInput from "./components/PersonsInput";
import DateSingleInput from "./components/DateSingleInput";
import ButtonSubmit from "./components/ButtonSubmit";
import CategoryPickerInput from "./components/CategoryPickerInput";

const HeroSearchForm = () => {
  const navigate = useNavigate();

  const [date, setDate] = useState(undefined);
  const [persons, setPersons] = useState(undefined);
  const [destinationId, setDestinationId] = useState(undefined);
  const [regionId, setRegionId] = useState(undefined);
  const [categoryId, setCategoryId] = useState(undefined);

  const handleSearch = () => {
    const params = {
      date,
      persons,
      destinationId,
      regionId,
      categoryId,
    };

    // Clear undefined params
    for (const key in params) {
      if (params[key] === undefined || params[key] === null) {
        delete params[key];
      }
    }

    navigate({
      pathname: "/search",
      search: createSearchParams(params).toString(),
    });
  };

  const handleChangeDestination = ({ destinationId, regionId }) => {
    setDestinationId(destinationId);
    setRegionId(regionId);
  };

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex flex-col md:flex-row  rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700  md:divide-y-0">
        <DestinationPickerInput onChange={handleChangeDestination} />

        <CategoryPickerInput onChange={(value) => setCategoryId(value)} />

        <DateSingleInput onChange={(date) => setDate(date)} />

        <PersonsInput onChange={(data) => setPersons(data)} />

        <div className="px-4 py-4 lg:py-0 flex items-center justify-center">
          <ButtonSubmit onClick={handleSearch} />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default HeroSearchForm;
