import { useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import Loading from "components/Loading/Loading";
import useNcId from "hooks/useNcId";

const mapItemToTaxonomy = (item, itemType) => {
  if (itemType === "destination") {
    return {
      id: item._id,
      name: item.title.EN,
      href: `/search?destinationId=${item._id}`,
      count: item.totalProducts,
      thumbnail: item.coverPhoto.url,
      taxonomy: itemType,
      listingType: "experiences",
    };
  }

  if (itemType === "category") {
    return {
      id: item._id,
      name: item.name.EN,
      href: `/search?categoryId=${item._id}`,
      count: item.totalProducts,
      thumbnail: item.photo[0].url,
      taxonomy: itemType,
      listingType: "experiences",
    };
  }

  return {};
};

const SectionSliderItems = ({
  heading = "",
  subHeading = "",
  className = "",
  itemClassName = "",
  items,
  loadingItems = false,
  itemType,
  itemsPerRow = 5,
  categoryCardType = "card5",
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const UNIQUE_CLASS =
    "SectionSliderNewCategories__" + uniqueClassName + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemsPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemsPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemsPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemsPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemsPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS, items]);

  useEffect(() => {
    MY_GLIDEJS.mount();
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  const renderCard = (item, index) => {
    const itemTaxonomy = mapItemToTaxonomy(item, itemType);

    switch (categoryCardType) {
      case "card3":
        return <CardCategory3 taxonomy={itemTaxonomy} />;
      case "card4":
        return <CardCategory4 taxonomy={itemTaxonomy} />;
      case "card5":
        return <CardCategory5 taxonomy={itemTaxonomy} />;
      default:
        return <CardCategory4 taxonomy={itemTaxonomy} />;
    }
  };

  return (
    <div className={`nc-SectionSliderItems ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        {heading && (
          <Heading
            desc={subHeading}
            hasNextPrev={sliderStyle === "style1" && items.length > itemsPerRow}
            isCenter={sliderStyle === "style2"}
          >
            {heading}
          </Heading>
        )}

        {loadingItems && <Loading />}

        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {items.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>

        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default SectionSliderItems;
